import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Grid } from '@mui/material';

function SuccessScoreCalculationTable() {
  return (
    <Grid 
      container 
      justifyContent="center"
      sx={{ paddingLeft: 2, marginTop: 3 }}
    >
      <TableContainer component={Paper} sx={{ width: 'auto', maxWidth: 750 }}>
        <Table sx={{ minWidth: 750 }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  FIP:AA:UserDiscoveryResponse
                </Typography>
              </TableCell>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  0th hour
                </Typography>
              </TableCell>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    12th hour
                  </Typography>
              </TableCell>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    24th hour
                  </Typography>
              </TableCell>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
              <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  42nd hour
                </Typography>
              </TableCell>
            </TableRow>

            {[
              ['Success count', '120', '110', '140', '100'],
              ['Total count', '140', '150', '150', '120'],
              ['Success Rate', '0.86', '0.73', '0.93', '0.83'],
              ['Weightage', '0.4', '0.3', '0.2', '0.1'],
              ['Weighted Success', '0.4*0.86 = 0.34', '0.3*0.73 = 0.22', '0.2*0.93 = 0.186', '0.1*0.83 = 0.083'],
              ['Weighted Performance Score', '0.829', '', '', ''],
            ].map(([param, val1, val2, val3, val4], index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {param}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {val1}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {val2}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {val3}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {val4}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default SuccessScoreCalculationTable;
