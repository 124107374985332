import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Grid } from '@mui/material';

function HealthApiRequestTable() {
  return (
    <Grid 
      container 
      justifyContent="center" 
      sx={{ paddingLeft: 2, marginTop: 3 }}
    >
      <TableContainer component={Paper} sx={{ width: 'auto', maxWidth: 750 }}>
        <Table sx={{ minWidth: 750 }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}> {/* Reduced padding */}
                <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  Authorization
                </Typography>
              </TableCell>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                  Bearer{" < Sahamati Token String >"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default HealthApiRequestTable;
