import React from 'react';
import { Grid, Typography, ListItem } from '@mui/material';
import BlackDot from './BlackDot';

function TwoColumnList() {
  return (
    <Grid container spacing={2} sx={{marginBottom:1.5}}>
      <Grid item xs={6}>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
           🔗 User Linking Response
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
           🔍 User Discovery Response
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            ✅ User Confirm Linking Response
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            📝 Consent Post Response
          </Typography>
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
             📥 FI Request Response
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
             📊 FI Fetch Response
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2}}>
          <Typography variant="body1">
             ❌ User Unlinking Response
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            🔔 FI Notification Response
          </Typography>
        </ListItem>
      </Grid>
    </Grid>
  );
}

export default TwoColumnList;
