import { Box, Link } from '@mui/material';

export default function HomeHeader() {
    return (
        <Box
            sx={{
                height: 64,
                display: 'flex', 
                justifyContent: 'flex-end', 
                alignItems: 'center', 
                borderBottom: "1px solid #D4CDE9",
                paddingX: { xs: 2, sm: 6 }, 
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
            }}
        >
            <Link
                href="mailto:support@cookiejar.co.in"
                underline="hover"
                target="_blank"
                rel="noreferrer"
                color='#0b3452'
                sx={{
                    fontWeight: '500',
                    fontSize: { xs: '0.9rem', sm: '1rem' }, 
                    transition: 'color 0.3s',
                    '&:hover': {
                        color: '#0056b3',
                    },
                }}
            >
                Support
            </Link>
        </Box>
    );
}
