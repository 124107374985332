// Sample Usage
const lensFAQs = [
	{
		question: 'What is the purpose of this API?',
		answer:
			'This API delivers comprehensive statistics on Financial Institution Providers (FIPs), including details on discovery requests and outcomes.',
	},
	{
		question: 'How do I authenticate?',
		answer:
			'You authenticate using a token in the Authorization header, provided by Sahamati to identify a participant in the ecosystem.',
	},
	{
		question: 'How is my identity as an FIU determined?',
		answer:
			'You are uniquely idenitified on the basis of the Sahamati token passed in the header of the API request. All data shown will be data specific to your organization.',
	},
	// Add more FAQs as needed
];
export default lensFAQs;
