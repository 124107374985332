import React from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FAQ {
	question: string;
	answer: string;
}

interface FAQSectionProps {
	title: string;
	faqs: FAQ[];
}

const FAQSection: React.FC<FAQSectionProps> = ({ title, faqs }) => {
	return (
		<div>
			<Typography variant="h6" gutterBottom>
				{title}
			</Typography>
			{faqs.map((faq, index) => (
				<Accordion key={index}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography fontSize={{ xs: '1.2rem', sm: '1rem' }}>
							{faq.question}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>{faq.answer}</Typography>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
};

export default FAQSection;
