import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Grid } from '@mui/material';

function HealthApiResponseTable() {
  return (
    <Grid 
      container 
      justifyContent="center"
      sx={{ paddingLeft: 2, marginTop: 3 }}
    >
      <TableContainer component={Paper} sx={{ width: 'auto', maxWidth: 750 }}>
        <Table sx={{ minWidth: 750 }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  Parameters
                </Typography>
              </TableCell>
              <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  Description
                </Typography>
              </TableCell>
            </TableRow>

            {[
              ['Date intervalDtHrMin', 'Minute at which data was fetched'],
              ['String entityId', 'FIP ID'],
              ['String eventName', 'Event Name: Linking, Account Discovery etc'],
              ['long avgTime', 'Average response time in millisecond over 1 min'],
              ['float successPercent', 'Percentage of successful attempts over 1 min'],
              ['float timeoutPercent', 'Percentage of attempts resulted into timeout over 1 min'],
              ['float accNotFoundPercent', 'Percentage of attempts resulted into not found error over 1 min'],
              ['float serverErrorPercent', 'Percentage of attempts resulted into server error over 1 min'],
              ['float clientErrorPercent', 'Percentage of attempts resulted into client error over 1 min'],
              ['int p50Time', '50th percentile of response time over 1 min'],
              ['int p95Time', '95th percentile of response time over 1 min'],
              ['int p99Time', '99th percentile of response time over 1 min'],
            ].map(([param, desc], index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {param}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {desc}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default HealthApiResponseTable;
