import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Grid } from '@mui/material';

function SuccessScoreRequestTable() {
    return (
        <Grid 
          container 
          justifyContent="center"
          sx={{ paddingLeft: 2, marginTop: 3 }}
        >
          <TableContainer component={Paper} sx={{ width: 'auto', maxWidth: 750 }}>
            <Table sx={{ minWidth: 750 }}>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      Parameters
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      Description
                    </Typography>
                  </TableCell>
                </TableRow>
    
                {[
                  ['range', '1HR'],
                  ['frequency', '[0, 12, 24, 48]'],
                  ['weightage', '[0.4, 0.3, 0.2, 0.1]'],
                ].map(([param, desc], index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                      <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {param}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                      <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {desc}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
    
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      );
}

export default SuccessScoreRequestTable;
