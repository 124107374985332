export interface IPage {
	title: string;
	href: string;
	children?: IPage[];
}

export const allPages: IPage[] = [
	{
		title: 'Home',
		href: '/',
	},
	{
		title: 'Fastrack',
		href: '/fastrack',
		children: [
			{
				title: 'Authentication',
				href: '/fastrack#auth',
			},
			{
				title: 'FIP Latest Health API',
				href: '/fastrack#health',
			},
			{
				title: 'FIP Success Score API',
				href: '/fastrack#score',
			},
		],
	},
	{
		title: 'Lens',
		href: '/lens',
		children: [
			{
				title: 'Authentication',
				href: '/lens#auth',
			},
			{
				title: 'Discovery Summary API',
				href: '/lens#discovery',
			},
		],
	},
	{
		title: 'JS SDK',
		href: '/js-sdk',
		children: [
			{
				title: 'Login',
				href: '/js-sdk#login',
			},
			{
				title: 'Verify OTP',
				href: '/js-sdk#otp',
			},
			{
				title: 'User Details Request',
				href: '/js-sdk#user-details',
			},
			{
				title: 'Mobile Verification Request',
				href: '/js-sdk#mobile-verification',
			},
			{
				title: 'Mobile Verification Verify Request',
				href: '/js-sdk#mobile-verify',
			},
			{
				title: 'User Linked Accounts',
				href: '/js-sdk#linked-accounts',
			},
			{
				title: 'Account Discovery',
				href: '/js-sdk#discovery',
			},
			{
				title: 'Account Linking',
				href: '/js-sdk#linking',
			},
			{
				title: 'Account confirm linking',
				href: '/js-sdk#confirm-linking',
			},
			{
				title: 'Consent Request Details',
				href: '/js-sdk#consent-request',
			},
			{
				title: 'Consent Approval/Denial Request',
				href: '/js-sdk#consent-process',
			},
			{
				title: 'Logout',
				href: '/js-sdk#logout',
			},
		],

	},
	{
		title: 'AA APIs',
		href: '/api',
		children: [
			{
				title: 'Initiate OTP Based Login',
				href: '/api#login',
			},
			{
				title: 'Verify OTP',
				href: '/api#verify-otp',
			},
			{
				title: 'Mobile Verification Request',
				href: '/api#mobile-verify-request',
			},
			{
				title: 'Mobile Verification Verify Request',
				href: '/api#mobile-verify',
			},
			{
				title: 'Linked Accounts',
				href: '/api#linked-accounts',
			},
			{
				title: 'Account Discovery',
				href: '/api#discovery',
			},
			{
				title: 'Account Discovery Async',
				href: '/api#discovery-async',
			},
			{
				title: 'Account Linking',
				href: '/api#linking',
			},
			{
				title: 'Confirm Account Linking',
				href: '/api#confirm-linking',
			},
			{
				title: 'Consent Request Details',
				href: '/api#consent-request',
			},
			{
				title: 'Consent Approval/Denial Request',
				href: '/api#consent-process',
			},
			{
				title: 'Consent Revoke Request',
				href: '/api#consent-revoke',
			},
			{
				title: 'Consent Handle Status Check Request',
				href: '/api#consent-handle-status',
			},
			{
				title: 'Logout',
				href: '/api#logout',
			}
		],
	}
];
