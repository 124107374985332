import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeLayout from './components/HomeLayout';
import { Container } from '@mui/material';
import Fastrack from './pages/Fastrack';
import HomePage from './pages/HomePage';
import LensPage from './pages/Lens';
import JavaScriptSDKPage from './pages/JavascriptSDKPage';
import WebsocketApiPage from './pages/WebsocketApiPage';

export default function App() {
	return (
		<Container disableGutters component="div" maxWidth={false}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomeLayout />}>
						<Route path="" element={<HomePage />} />
						<Route path="/fastrack" element={<Fastrack />} />
						<Route path="/lens" element={<LensPage />} />
						<Route path="/js-sdk" element={<JavaScriptSDKPage />} />
						<Route path="/api" element={<WebsocketApiPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</Container>
	);
}
