import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Grid } from '@mui/material';

function SuccessScoreResponseTable() {
    return (
        <Grid 
          container 
          justifyContent="center"
          sx={{ paddingLeft: 2, marginTop: 3 }}
        >
          <TableContainer component={Paper} sx={{ width: 'auto', maxWidth: 750 }}>
            <Table sx={{ minWidth: 750 }}>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      Parameters
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      Description
                    </Typography>
                  </TableCell>
                </TableRow>
    
                {[
                  ['entityId', 'FIP Id'],
                  ['eventName', 'Event such as Discovery Response, User Linking Response etc'],
                  ['value', 'Weightage Success Rate over given range with specified frequency'],
                  ['avgResponseTime', 'Response Time Averaged over all data intervals in Milliseconds'],
                  ['minResponseTime', 'Minimun Time calculated over all data intervals in Milliseconds'],
                  ['maxResponseTime', 'Maximum Time calculated over all data intervals in Milliseconds'],
                  ['volumeIndex', 'Indicator of total volume served over all data intervals'],
                ].map(([param, desc], index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                      <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {param}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: '1px solid black', padding: '6px 12px' }}>
                      <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {desc}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
    
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      );
}

export default SuccessScoreResponseTable;
