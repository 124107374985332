import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Typography,
	Grid,
} from '@mui/material';

const ParamTable = ({ headers, values }) => {
	return (
		<Grid
			container
			justifyContent="center"
			sx={{ paddingLeft: 2, marginTop: 3 }}
		>
			<TableContainer component={Paper} sx={{ width: 'auto', maxWidth: 750 }}>
				<Table sx={{ minWidth: 750 }}>
					<TableBody>
						{headers.map((header, index) => (
							<TableRow key={index}>
								<TableCell
									sx={{ border: '1px solid black', padding: '6px 12px' }}
								>
									<Typography
										variant="body1"
										sx={{ textAlign: 'center', fontWeight: 'bold' }}
									>
										{header}
									</Typography>
								</TableCell>
								<TableCell
									sx={{ border: '1px solid black', padding: '6px 12px' }}
								>
									<Typography variant="body1" sx={{ textAlign: 'center' }}>
										{values[index]}
									</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
};

export default ParamTable;
