import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './WebsocketApiPage.css';
import { idText } from 'typescript';

const introduction = [
	{
		title: "Integration Approach",
		content: `
      FIU's application client running on the user's device (browser or mobile app) can integrate with 
      Finvu AA by directly invoking the Finvu AA client APIs over WebSocket connections to the AA server.
      Before establishing a WebSocket connection, FIU must raise a consent request with Finvu AA using the 
      /Consent ReBIT API. This is a server-to-server call. OTP-based authentication ensures that the user's 
      credentials are secure, and all sensitive actions occur in the AA's domain.
    `,
	},
	{
		title: "WebSocket Request/Response Flow",
		content: `
      1. FIU initiates a server-to-server /Consent API call to obtain a consent handle.
      2. FIU client opens a WebSocket connection and provides consent handle, user ID, or mobile number.
      3. Finvu AA sends OTP to the user's mobile, which the client then verifies.
      4. Upon successful OTP verification, a session ID is returned for subsequent API calls.
      5. The session ends when the client invokes the Logout API.
    `,
	},
	{
		title: "WebSocket Request/Response Format",
		content: `
      Request:
      {
        "header": {
          "mid": "unique-message-id",
          "ts": "timestamp",
          "sid": "session-id",
          "dup": false,
          "type": "urn:finvu:in:app:req.example"
        },
        "payload": { /* request-specific data */ }
      }

      Response:
      {
        "header": {
          "mid": "unique-message-id",
          "ts": "timestamp",
          "sid": "session-id",
          "dup": false,
          "type": "urn:finvu:in:app:res.example"
        },
        "payload": { /* response-specific data */ }
      }
    `,
	},
];

const apis = [
	{
		idString: "login",
		title: "Initiate OTP Based Login",
		urn: "urn:finvu:in:app:req.loginOtp.01",
		description: "Initiates login via OTP for the user.",
		requestSchema: {
			username: "testuser@finvu",
			mobileNum: "9960144500",
			handleId: "5e8bae5c-3b9f-4c36-8392-f87b24734d64",
		},
		responseSchema: {
			status: "SEND",
			message: "Otp Sent",
			otpReference: "hash_combination_of_user_and_mobile",
			maskedId: "xxxxuser@finvu",
		},
		fields: [
			{ name: "username", description: "User name format for login.", required: false },
			{ name: "mobileNum", description: "User's mobile number.", required: false },
			{ name: "handleId", description: "Consent handle ID.", required: true },
		],
		errors: [
			{ code: "RETRY", description: "Temporary server issue. Retry after some time." },
		],
	},
	{
		idString: "verify-otp",
		title: "Verify OTP",
		urn: "urn:finvu:in:app:req.loginOtpVerify.01",
		description: "Verifies the OTP sent to the user's registered mobile number.",
		requestSchema: {
			otpReference: "hash_combination_from_otp_initiation",
			otp: "082370",
		},
		responseSchema: {
			status: "SUCCESS",
			message: "Welcome!",
			userId: "abcd@finvu",
		},
		fields: [
			{ name: "otpReference", description: "Hash from OTP initiation response.", required: true },
			{ name: "otp", description: "The OTP sent to the user’s mobile.", required: true },
		],
	},
	{
		idString: "mobile-verify-request",
		title: "Mobile Verification Request",
		urn: "urn:finvu:in:app:req.mobileVerification.01",
		description: "Initiates mobile number verification by sending an OTP.",
		requestSchema: {
			mobileNum: "8421068174",
		},
		responseSchema: {
			status: "SEND",
			message: "Otp Sent",
		},
		fields: [
			{ name: "mobileNum", description: "The mobile number to verify.", required: true },
		],
	},
	{
		idString: "mobile-verify",
		title: "Mobile Verification Verify Request",
		urn: "urn:finvu:in:app:req.mobileVerificationVerify.01",
		description: "Verifies the OTP sent during mobile verification.",
		requestSchema: {
			mobileNum: "8421068174",
			otp: 421867,
		},
		responseSchema: {
			status: "ACCEPT",
			message: "Mobile authentication successful.",
		},
		fields: [
			{ name: "mobileNum", description: "Mobile number used in the request.", required: true },
			{ name: "otp", description: "The OTP sent for verification.", required: true },
		],
	},
	{
		idString: "linked-accounts",
		title: "User Linked Accounts",
		urn: "urn:finvu:in:app:req.userLinkedAccount.01",
		description: "Fetches the list of accounts linked to the user's Finvu AA profile.",
		requestSchema: {
			userId: "xxxxuser@finvu",
		},
		responseSchema: {
			status: "SUCCESS",
			LinkedAccounts: [
				{
					userId: "finvudemo@finvu",
					fipId: "BARB0KIMXXX",
					fipName: "Finvu Bank",
					maskedAccNumber: "XXXXXXX3054",
				},
			],
		},
		fields: [
			{ name: "userId", description: "User ID to fetch linked accounts.", required: true },
		],
	},
	{
		idString: "discovery",
		title: "Account Discovery",
		urn: "urn:finvu:in:app:req.discover.01",
		description: "Discovers accounts held by the user in a specific FIP.",
		requestSchema: {
			ver: "1.1.2",
			timestamp: "2021-06-29T10:51:10.855Z",
			txnid: "f8d5e43c-4851-4f1f-b845-ad663d0b6026",
			Customer: {
				id: "webdemo@finvu",
				Identifiers: [
					{
						category: "STRONG",
						type: "MOBILE",
						value: "8421068174",
					},
				],
			},
			FIPDetails: {
				fipId: "BARB0KIMXXX",
				fipName: "Finvu Bank",
			},
			FITypes: ["DEPOSIT", "RECURRING_DEPOSIT", "TERM-DEPOSIT"],
		},
		responseSchema: {
			status: "SUCCESS",
			DiscoveredAccounts: [
				{
					accType: "CURRENT",
					accRefNumber: "REF254178",
					maskedAccNumber: "XXXXX4178",
					FIType: "DEPOSIT",
				},
			],
		},
		fields: [
			{ name: "ver", description: "Version of ReBIT specs.", required: true },
			{ name: "timestamp", description: "Timestamp of the request.", required: true },
			{ name: "Customer", description: "Customer details for discovery.", required: true },
			{ name: "FIPDetails", description: "Details of the FIP for discovery.", required: true },
			{ name: "FITypes", description: "List of financial information types for discovery.", required: true },
		],
	},
	{
		idString: "discovery-async",
		title: "Account Discovery Async",
		urn: "urn:finvu:in:app:req.discoverAsync.01",
		description:
			"Performs account discovery asynchronously, allowing non-blocking requests.",
		requestSchema: {
			ver: "1.1.2",
			timestamp: "2021-06-29T10:51:10.855Z",
			txnid: "f8d5e43c-4851-4f1f-b845-ad663d0b6026",
			Customer: {
				id: "webdemo@finvu",
				Identifiers: [
					{
						category: "STRONG",
						type: "MOBILE",
						value: "8421068174",
					},
				],
			},
			FIPDetails: {
				fipId: "BARB0KIMXXX",
				fipName: "Finvu Bank",
			},
			FITypes: ["DEPOSIT", "RECURRING_DEPOSIT", "TERM-DEPOSIT"],
		},
		responseSchema: {
			status: "SUCCESS",
			DiscoveredAccounts: [
				{
					accType: "CURRENT",
					accRefNumber: "REF254178",
					maskedAccNumber: "XXXXX4178",
					FIType: "DEPOSIT",
				},
			],
		},
		fields: [
			{ name: "ver", description: "Version of ReBIT specs.", required: true },
			{ name: "timestamp", description: "Timestamp of the request.", required: true },
			{ name: "Customer", description: "Customer details for discovery.", required: true },
			{ name: "FIPDetails", description: "Details of the FIP for discovery.", required: true },
			{ name: "FITypes", description: "List of financial information types for discovery.", required: true },
		],
	},
	{
		idString: "linking",
		title: "Account Linking",
		urn: "urn:finvu:in:app:req.linking.01",
		description: "Initiates linking of a discovered account.",
		requestSchema: {
			ver: "1.1.2",
			timestamp: "2021-06-29T10:51:19.763Z",
			txnid: "8cc9b944-f599-45ec-9f77-24108e7a70b8",
			FIPDetails: {
				fipId: "BARB0KIMXXX",
				fipName: "Finvu Bank",
			},
			Customer: {
				id: "webdemo@finvu",
				Accounts: [
					{
						maskedAccNumber: "XXXXXX1068",
						accRefNumber: "REF8421068",
						FIType: "DEPOSIT",
						accType: "SAVINGS",
					},
				],
			},
		},
		responseSchema: {
			status: "SUCCESS",
			AuthenticatorType: "TOKEN",
			RefNumber: "071740e5-c489-4dbc-b846-abaef3b2eb31",
		},
		fields: [
			{ name: "ver", description: "Version of ReBIT specs.", required: true },
			{ name: "timestamp", description: "Timestamp of the request.", required: true },
			{ name: "Customer", description: "Customer object with accounts to link.", required: true },
			{ name: "FIPDetails", description: "Details of the FIP for linking.", required: true },
		],
	},
	{
		idString: "confirm-linking",
		title: "Account Confirm Linking",
		urn: "urn:finvu:in:app:req.confirm-token.01",
		description: "Confirms account linking using the token sent by FIP.",
		requestSchema: {
			ver: "1.1.2",
			timestamp: "2021-06-29T10:51:36.269Z",
			txnid: "de3115b6-a367-4153-9890-700cc6967e70",
			AccountsLinkingRefNumber: "071740e5-c489-4dbc-b846-abaef3b2eb31",
			token: 169888,
		},
		responseSchema: {
			status: "SUCCESS",
			AccLinkDetails: [
				{
					customerAddress: "webdemo@finvu",
					linkRefNumber: "86e126fa-fb9c-4084-97a7-8848adc5817e",
					accRefNumber: "REF8421068",
					status: "LINKED",
				},
			],
		},
		fields: [
			{ name: "ver", description: "Version of ReBIT specs.", required: true },
			{ name: "timestamp", description: "Timestamp of the request.", required: true },
			{ name: "AccountsLinkingRefNumber", description: "Linking reference number.", required: true },
			{ name: "token", description: "OTP sent by FIP.", required: true },
		],
	},
	{
		idString: "consent-request",
		title: "Consent Request Details",
		urn: "urn:finvu:in:app:req.consentRequestDetails.01",
		description: "Fetches details of a specific consent request.",
		requestSchema: {
			consentHandleId: "59449367-a37e-4f31-8f4c-3eb858002c2e",
			userId: "xxxdemo@finvu",
		},
		responseSchema: {
			status: "SUCCESS",
			Purpose: {
				code: "101",
				refUri: "https://api.rebit.org.in/aa/purpose/101.xml",
				text: "Wealth management service",
			},
			consentTypes: ["PROFILE", "TRANSACTIONS", "SUMMARY"],
			DataLife: {
				unit: "YEAR",
				value: 5,
			},
		},
		fields: [
			{ name: "consentHandleId", description: "The consent handle ID.", required: true },
			{ name: "userId", description: "User ID for informational purposes.", required: false },
		],
	},
	{
		idString: "consent-process",
		title: "Consent Approval/Denial Request",
		urn: "urn:finvu:in:app:req.accountConsentRequest.01",
		description: "Handles user approval or denial of a consent request.",
		requestSchema: {
			FIPDetails: [
				{
					FIP: {
						id: "BARB0KIMXXX",
					},
					Accounts: [
						{
							linkRefNumber: "86e126fa-fb9c-4084-97a7-8848adc5817e",
							accType: "SAVINGS",
							accRefNumber: "REF8421068",
						},
					],
				},
			],
			FIU: {
				id: "fiu@demofinance",
			},
			ver: "1.1.2",
			consentHandleId: "59449367-a37e-4f31-8f4c-3eb858002c2e",
			handleStatus: "ACCEPT",
		},
		responseSchema: {
			status: "SUCCESS",
			message: "Consent stored.",
			fipConsentInfos: [
				{
					consentId: "adaf97ff-c03a-4a11-883e-f419da857f8b",
				},
			],
		},
		fields: [
			{ name: "FIPDetails", description: "Details of selected FIP and accounts.", required: true },
			{ name: "FIU", description: "Details of the FIU initiating the consent request.", required: true },
			{ name: "ver", description: "Version of the API.", required: true },
			{ name: "consentHandleId", description: "Consent handle ID being approved/denied.", required: true },
			{ name: "handleStatus", description: "ACCEPT or DENY status.", required: true },
		],
	},
	{
		idString: "consent-revoke",
		title: "Consent Revoke Request",
		urn: "urn:finvu:in:app:req.consent-revoke.01",
		description: "Revokes a previously approved consent.",
		requestSchema: {
			userId: "user@finvu",
			consentId: "f7d1e843-3be4-4096-8e3c-7e86b20ad877",
			revokeType: "REVOKED",
		},
		responseSchema: {
			status: "SUCCESS",
			message: "Consent id <consent id> is REVOKED.",
		},
		fields: [
			{ name: "userId", description: "User ID of the consent owner.", required: true },
			{ name: "consentId", description: "ID of the consent to be revoked.", required: true },
			{ name: "revokeType", description: "REVOKED status indicating revocation.", required: true },
		],
	},
	{
		idString: "consent-handle-status",
		title: "Consent Handle Status Check Request",
		urn: "urn:finvu:in:app:req.consentHandleStatus.01",
		description: "Checks the status of a specific consent handle.",
		requestSchema: {
			handleId: "59449367-a37e-4f31-8f4c-3eb858002c2e",
		},
		responseSchema: {
			status: "SUCCESS",
			handleStatus: "READY",
		},
		fields: [
			{ name: "handleId", description: "The consent handle ID to check status for.", required: true },
		],
	},
	{
		idString: "logout",
		title: "Logout",
		urn: "urn:finvu:in:app:req.logout.01",
		description: "Terminates the user's session with Finvu AA.",
		requestSchema: {
			userId: "xxxxuser@finvu",
		},
		responseSchema: {
			status: "SUCCESS",
			message: "User testuser@finvu logged out.",
		},
		fields: [
			{ name: "userId", description: "User ID for logout.", required: true },
		],
	}

];

const WebsocketApiPage = () => {
	return (
		<div className="documentation">
			<main className="content">
				{introduction.map((section, index) => (
					<section key={index} id={section.title.replace(/\s+/g, '-')}>
						<h2>{section.title}</h2>
						<pre>{section.content}</pre>
					</section>
				))}
				{apis.map((api, index) => (
					<section key={index} id={api.title.replace(/\s+/g, '-')}>
						<h2>{api.title}</h2>
						<p><strong>URN:</strong> {api.urn}</p>
						<p>{api.description}</p>

						<h3>Request Schema:</h3>
						<SyntaxHighlighter language="json" style={docco}>
							{JSON.stringify(api.requestSchema, null, 2)}
						</SyntaxHighlighter>

						<h3>Response Schema:</h3>
						<SyntaxHighlighter language="json" style={docco}>
							{JSON.stringify(api.responseSchema, null, 2)}
						</SyntaxHighlighter>

						<h3>Fields:</h3>
						<ul>
							{api.fields.map((field, idx) => (
								<li key={idx}>
									<strong>{field.name}:</strong> {field.description}
									{field.required ? " (Required)" : " (Optional)"}
								</li>
							))}
						</ul>

						{api.errors && (
							<>
								<h3>Errors:</h3>
								<ul>
									{api.errors.map((error, idx) => (
										<li key={idx}>
											<strong>{error.code}:</strong> {error.description}
										</li>
									))}
								</ul>
							</>
						)}
					</section>
				))}
			</main>
		</div>
	);
};

export default WebsocketApiPage;
