import React from 'react';
import { Box } from '@mui/material';

function BlackDot() {
  return (
    <Box
      sx={{
        width: 8,       
        height: 8,      
        backgroundColor: 'black',
        borderRadius: '50%',
        display: 'inline-block'
      }}
    />
  );
}

export default BlackDot;