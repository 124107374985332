import React from "react";
import { Box, Typography, Container } from "@mui/material";

const JavaScriptSDKPage = () => {
	return (
		<Container>
			<Typography variant="h2" gutterBottom>
				Finvu JS SDK Integration
			</Typography>
			<Typography variant="body1">
				Version: 1.02
			</Typography>
			<Typography variant="body2" color="textSecondary">
				Last Updated: 16-Jun-2022
			</Typography>

			<Box mt={4}>
				<Typography variant="h3" gutterBottom>
					Integration Overview
				</Typography>
				<Typography variant="body1" gutterBottom>
					The Finvu WebSocket wrapper library enables seamless interaction between FIUs and Finvu AA.
					It can be embedded in any single-page web application.
				</Typography>
				<CodeBlock
					code={`<script src="<cdn path>/sdk/dev/finvu-client-sdk.js"></script>
<script>
  finvuClient.open();
  // API calls go here
</script>`}
					language="html"
				/>
			</Box>

			<APIDocumentation />
		</Container>
	);
};

const APIDocumentation = () => {
	const apiDetails = [
		{
			idString: "login",
			title: "1. Initiate OTP-based Login",
			description: "Initiates the OTP-based login process for a user.",
			requestSchema: {
				handleId: "String",
				username: "String (optional, conditional)",
				mobileNum: "String (optional, conditional)",
			},
			requestExample: `finvuClient.login(handleID, username, mobileNumber).then((response) => {
  console.log(response);
});`,
			fields: [
				{ name: "handleId", description: "Consent handle returned by Finvu AA.", required: "Required" },
				{ name: "username", description: "User name (e.g., testuser@finvu).", required: "Optional (conditional)" },
				{ name: "mobileNum", description: "Mobile number for OTP delivery.", required: "Optional (conditional)" },
			],
			responseSchema: {
				Status: "String",
				message: "String",
			},
			successResponse: `{
  Status: "SEND",
  message: "OTP sent"
}`,
			errorResponses: [
				`{
  Status: "FAILURE",
  message: "Invalid username"
}`,
			],
		},
		{
			idString: "otp",
			title: "2. Verify OTP",
			description: "Verifies the OTP sent to the user's mobile number.",
			requestSchema: {
				otp: "String",
			},
			requestExample: `finvuClient.verifyOTP(otp).then((response) => {
  console.log(response);
});`,
			fields: [
				{ name: "otp", description: "OTP sent to the user’s mobile number.", required: "Required" },
			],
			responseSchema: {
				Status: "String",
				message: "String",
			},
			successResponse: `{
  Status: "SUCCESS",
  message: "Welcome!"
}`,
			errorResponses: [
				`{
  Status: "FAILURE",
  message: "Invalid OTP"
}`,
				`{
  Status: "FAILURE",
  message: "OTP already used or expired"
}`,
			],
		},
		{
			idString: "user-details",
			title: "3. User Details Request",
			description: "Fetches details of the currently logged-in user.",
			requestSchema: {},
			requestExample: `finvuClient.userDetails().then((response) => {
  console.log(response);
});`,
			fields: [],
			responseSchema: {
				status: "String",
				message: "String",
				UserInfo: {
					userId: "String",
					mobileNo: "String",
					mobileAuthenticated: "String",
					emailId: "String",
					emailAuthenticated: "String",
				},
			},
			successResponse: `{
  "status": "ACCEPT",
  "message": "Received UserInfo Successfully",
  "UserInfo": {
    "userId": "8076729178@finvu",
    "mobileNo": "8076729178",
    "mobileAuthenticated": "Y",
    "emailId": "",
    "emailAuthenticated": "N"
  }
}`,
			errorResponses: [],
		},
		{
			idString: "mobile-verification",
			title: "4. Mobile Verification Request",
			description: "This API is used when discovery/linking is to be performed on a mobile number other than the registered one.",
			requestSchema: {
				mobileNum: "String",
			},
			requestExample: `finvuClient.mobileVerificationRequest(mobileNumber).then((response) => {
		  console.log(response);
		});`,
			fields: [
				{ name: "mobileNum", description: "The mobile number to be verified.", required: "Required" },
			],
			responseSchema: {
				Status: "String",
				message: "String",
			},
			successResponse: `{
		  Status: "SEND",
		  message: "OTP sent"
		}`,
			errorResponses: [
				`{
		  Status: "FAILURE",
		  message: "Invalid mobile"
		}`,
			],
		},
		{
			idString: "mobile-verify",
			title: "5. Mobile Verification Verify Request",
			description: "Verifies the OTP sent to the mobile number for verification.",
			requestSchema: {
				mobileNum: "String",
				otp: "String",
			},
			requestExample: `finvuClient.mobileVerificationVerifyRequest(mobileNumber, otp).then((response) => {
		  console.log(response);
		});`,
			fields: [
				{ name: "mobileNum", description: "The mobile number to be verified.", required: "Required" },
				{ name: "otp", description: "OTP sent to the user’s mobile number.", required: "Required" },
			],
			responseSchema: {
				Status: "String",
				message: "String",
			},
			successResponse: `{
		  Status: "ACCEPT",
		  message: "Mobile Authentication successful"
		}`,
			errorResponses: [
				`{
		  Status: "FAILURE",
		  message: "Invalid mobile number"
		}`,
			],
		},
		{
			idString: "linked-accounts",
			title: "6. User Linked Accounts",
			description: "Fetches the list of accounts already linked to the user.",
			requestSchema: {},
			requestExample: `finvuClient.userLinkAccount().then((response) => {
		  console.log(response);
		});`,
			fields: [],
			responseSchema: {
				status: "String",
				message: "String",
				LinkedAccounts: "Array of Linked Account Objects",
			},
			successResponse: `{
		  "status": "SUCCESS",
		  "message": null,
		  "LinkedAccounts": [
			{
			  "userId": "finvudemo@finvu",
			  "fipId": "BARB0KIMXXX",
			  "fipName": "Finvu Bank",
			  "maskedAccNumber": "XXXXXXX3054",
			  "accType": "CURRENT"
			}
		  ]
		}`,
			errorResponses: [
				`{
		  "status": "RECORD-NOT-FOUND",
		  "message": "Account not yet linked."
		}`,
			],
		},
		{
			idString: "discovery",
			title: "7. Account Discovery",
			description: "Discovers accounts associated with a given FIP (Financial Information Provider).",
			requestSchema: {
				fipid: "String",
				identifiers: "Array of Identifier Objects",
			},
			requestExample: `finvuClient.discoveryAccounts(fipid, identifiers).then((response) => {
		  console.log(response);
		});`,
			fields: [
				{ name: "fipid", description: "FIP ID as per the central registry.", required: "Required" },
				{ name: "identifiers", description: "List of identifiers such as mobile numbers.", required: "Required" },
			],
			responseSchema: {
				status: "String",
				message: "String",
				DiscoveredAccounts: "Array of Account Objects",
			},
			successResponse: `{
		  "status": "SUCCESS",
		  "message": null,
		  "DiscoveredAccounts": [
			{
			  "accType": "SAVINGS",
			  "accRefNumber": "BOB352467108",
			  "maskedAccNumber": "XXXXXXXXX2108",
			  "FIType": "DEPOSIT"
			}
		  ]
		}`,
			errorResponses: [
				`{
		  Status: "FAILURE",
		  message: "Invalid identifier"
		}`,
			],
		},
		{
			idString: "linking",
			title: "8. Account Linking",
			description: "Links the user's accounts discovered during the discovery process.",
			requestSchema: {
				fipid: "String",
				account: "Array of Account Objects",
			},
			requestExample: `finvuClient.accountLinking(fipid, account).then((response) => {
		  console.log(response);
		});`,
			fields: [
				{ name: "fipid", description: "FIP ID as per the central registry.", required: "Required" },
				{
					name: "account",
					description:
						"List of accounts to link. Must include fields such as maskedAccNumber, accRefNumber, FIType, and accType.",
					required: "Required",
				},
			],
			responseSchema: {
				Status: "String",
				message: "String",
			},
			successResponse: `{
		  Status: "SUCCESS",
		  message: ""
		}`,
			errorResponses: [
				`{
		  Status: "FAILURE",
		  message: "Invalid FIPID"
		}`,
			],
		},
		{
			idString: "confirm-linking",
			title: "9. Account Confirm Linking",
			description: "Confirms the linking of a user's account using the linking reference number and OTP.",
			requestSchema: {
				accountLinkRefNumber: "String",
				otp: "String",
			},
			requestExample: `finvuClient.accountConfirmLinking(accountLinkRefNumber, otp).then((response) => {
		  console.log(response);
		});`,
			fields: [
				{ name: "accountLinkRefNumber", description: "Linking reference number returned in the linking request.", required: "Required" },
				{ name: "otp", description: "OTP sent to the user’s mobile number.", required: "Required" },
			],
			responseSchema: {
				Status: "String",
				message: "String",
			},
			successResponse: `{
		  Status: "SUCCESS",
		  message: ""
		}`,
			errorResponses: [
				`{
		  Status: "FAILURE",
		  message: "Invalid FIPID"
		}`,
			],
		},
		{
			idString: "consent-request",
			title: "10. Consent Request Details",
			description: "Fetches details about a consent request, such as purpose, accounts, and duration.",
			requestSchema: {},
			requestExample: `finvuClient.consentRequestDetails().then((response) => {
		  console.log(response);
		});`,
			fields: [],
			responseSchema: {
				status: "String",
				message: "String",
				consentId: "String",
				Purpose: {
					code: "String",
					text: "String",
					Category: {
						type: "String",
					},
				},
				Accounts: "Array of Account Objects",
				DataLife: {
					unit: "String",
					value: "Number",
				},
				Frequency: {
					unit: "String",
					value: "Number",
				},
				DataFilter: "Array of Data Filters",
			},
			successResponse: `{
		  "status": "SUCCESS",
		  "message": null,
		  "consentId": "e5601e50-ea04-4fb9-a3ef-6377048a98ff",
		  "Purpose": {
			"code": "105",
			"text": "Explicit one-time consent for the accounts",
			"Category": {
			  "type": "Account Query and Monitoring"
			}
		  },
		  "Accounts": null,
		  "DataLife": {
			"unit": "DAY",
			"value": 1
		  },
		  "Frequency": {
			"unit": "YEAR",
			"value": 0
		  },
		  "DataFilter": [
			{
			  "type": "TRANSACTIONAMOUNT",
			  "operator": ">",
			  "value": "0"
			}
		  ]
		}`,
			errorResponses: [
				`{
		  Status: "FAILURE",
		  message: "Consent not found"
		}`,
			],
		},
		{
			idString: "consent-process",
			title: "11. Consent Approval/Denial Request",
			description: "Handles the approval or denial of a consent request.",
			requestSchema: {
				FIPDetails: "Array of FIP Details Objects",
				handleStatus: "String (ACCEPT/DENY)",
			},
			requestExample: `finvuClient.consentApproveRequest(FIPDetails, handleStatus).then((response) => {
		  console.log(response);
		});`,
			fields: [
				{
					name: "FIPDetails",
					description: "Details of the FIP and accounts for which consent is being approved or denied.",
					required: "Required",
				},
				{ name: "handleStatus", description: "ACCEPT or DENY the consent request.", required: "Required" },
			],
			responseSchema: {
				status: "String",
				message: "String",
				consentId: "String",
			},
			successResponse: `{
		  "status": "SUCCESS",
		  "message": null,
		  "consentId": "e5601e50-ea04-4fb9-a3ef-6377048a98ff"
		}`,
			errorResponses: [
				`{
		  Status: "FAILURE",
		  message: "Invalid consent details"
		}`,
			],
		},
		{
			idString: "logout",
			title: "12. Logout",
			description: "Logs out the user and closes the WebSocket connection.",
			requestSchema: {},
			requestExample: `finvuClient.logout().then((response) => {
		  console.log(response);
		});`,
			fields: [],
			responseSchema: {
				Status: "String",
				message: "String",
			},
			successResponse: `{
		  Status: "SUCCESS",
		  message: "Successfully Logout"
		}`,
			errorResponses: [
				`{
		  Status: "FAILURE",
		  message: "Already logged out"
		}`,
			],
		}
	];

	return (
		<Box>
			{apiDetails.map((api, index) => (
				<APISection key={index} {...api} />
			))}
		</Box>
	);
};

interface APISectionProps {
	idString: string;
	title: string;
	description: string;
	requestSchema: object;
	requestExample: string;
	fields: { name: string; description: string; required: string }[];
	responseSchema: object;
	successResponse: string;
	errorResponses: string[];
}

const APISection = ({
	idString,
	title,
	description,
	requestSchema,
	requestExample,
	fields,
	responseSchema,
	successResponse,
	errorResponses,
}: APISectionProps) => {
	return (
		<Box mt={4}>
			<span id={idString}></span>
			<Typography variant="h4" gutterBottom>
				{title}
			</Typography>
			<Typography variant="body1" gutterBottom>
				{description}
			</Typography>

			<Typography variant="h5" gutterBottom>
				Request Schema
			</Typography>
			<CodeBlock code={JSON.stringify(requestSchema, null, 2)} language="json" />

			<Typography variant="h5" gutterBottom>
				Request Example
			</Typography>
			<CodeBlock code={requestExample} language="javascript" />

			{fields.length > 0 && (
				<>
					<Typography variant="h6">Fields</Typography>
					<ul>
						{fields.map((field, idx) => (
							<li key={idx}>
								<b>{field.name}</b>: {field.description} <i>({field.required})</i>
							</li>
						))}
					</ul>
				</>
			)}

			<Typography variant="h5" gutterBottom>
				Response Schema
			</Typography>
			<CodeBlock code={JSON.stringify(responseSchema, null, 2)} language="json" />

			<Typography variant="h5" gutterBottom>
				Success Response Example
			</Typography>
			<CodeBlock code={successResponse} language="json" />

			{errorResponses.length > 0 && (
				<>
					<Typography variant="h5" gutterBottom>
						Error Responses
					</Typography>
					{errorResponses.map((error: string, idx: React.Key | null | undefined) => (
						<CodeBlock key={idx} code={error} language="json" />
					))}
				</>
			)}
		</Box>
	);
};

const CodeBlock = ({ code, language }: { code: string; language: string }) => (
	<Box
		sx={{
			backgroundColor: "#f5f5f5",
			borderRadius: "5px",
			padding: "10px",
			margin: "10px 0",
			overflow: "auto",
		}}
	>
		<pre>
			<code>{code}</code>
		</pre>
	</Box>
);

export default JavaScriptSDKPage;
