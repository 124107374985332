import React, { useEffect } from 'react';
import RapiDocReact from '../components/RapidDocReact';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import BlackDot from '../components/BlackDot';
import HealthApiResponseTable from '../components/HealthApiResponseTable';
import FAQSection from '../components/FAQSection';
import lensFAQs from '../constants/lensFAQs';
import ParamTable from '../components/table/ParamTable';
function navigateToSection(hash: string) {
	const element = document.getElementById(hash);
	if (element) {
		let headerOffset = 45;
		let elementPosition = element.getBoundingClientRect().top;
		let offsetPosition = elementPosition + window.scrollY - headerOffset;
		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth',
		});
	}
}

const LensPage: React.FC = React.memo(() => {
	const location = useLocation();
	useEffect(() => {
		if (location.hash.length === 0) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
			return;
		}

		const hash = location.hash.slice(1);
		navigateToSection(hash);
	}, [location.hash]);

	const reqHeaders = ['Authorization', 'startDate', 'endDate'];
	const reqValues = [
		'Bearer <Sahamati Token String>',
		'Date from which metrics are needed',
		'Date till which metrics are needed',
	];

	const resHeaders = [
		'fiu_id',
		'discovery_date',
		'fip_id',
		'SUCCESS',
		'FAILURE',
		'RECORD-NOT-FOUND',
		'TOTAL',
	];
	const resValues = [
		'The unique ID of the FIU for which the metrics are presented',
		'Date when the discovery request was made on AA',
		'The unique ID of the FIP for which discovery request was sent',
		'The count of discovery requests where accounts were successfully discovered during that time period',
		'The count of discovery requests where some technical error occured and accounts were not discovered',
		'The count of discovery requests where the FIP returned an instance of "RECORD-NOT-FOUND"; no valid accounts were discovered during that time period',
		'The total number of discovery requests during that time period',
	];

	return (
		<>
			<Box
				sx={{
					paddingLeft: 4,
				}}
			>
				<Typography
					sx={{
						fontWeight: 'bold',
						marginTop: 2,
						fontSize: { xs: '1.2rem', sm: '1.5rem' },
						display: 'inline-block',
						marginBottom: 1,
						color: '#0b3452',
						lineHeight: 1.5,
					}}
				>
					Lens
				</Typography>
				<Box
					sx={{
						marginTop: 1,
						fontWeight: 'bold',
						fontSize: { xs: '1.2rem', sm: '1.5rem' },
						display: 'inline-block',
						marginBottom: 0.75,
						lineHeight: 1.5,
						paddingRight: 3,
					}}
				>
					<Typography
						gutterBottom
						sx={{ fontWeight: 'bold', color: '#0b3452' }}
					>
						Discovery Summary API
					</Typography>

					<Typography variant="body1" gutterBottom>
						Get comprehensive discovery statistics on Financial Institution
						Providers (FIPs), including the total number of discovery requests,
						successful discoveries, failures, and instances of 'Record Not
						Found' within a specified timeframe. It helps FIUs drill down into
						the performance of individual FIPs, providing critical insights into
						why users may drop off during the discovery process.
					</Typography>

					<Typography
						sx={{ fontWeight: 'bold', marginBottom: 1, color: '#0b3452' }}
					>
						Metric Calculation:
					</Typography>

					<Typography sx={{ marginBottom: 2, paddingLeft: 2 }}>
						<BlackDot /> All metrics are{' '}
						<span style={{ fontWeight: '700' }}>
							specific to the FIU using the API; i.e you will be able to see
							metrics specific to your organisations' activity.
						</span>
					</Typography>
					<Typography sx={{ marginBottom: 2, paddingLeft: 2 }}>
						<BlackDot /> Metrics are calculated based on the discovery response
						data available from{' '}
						<span style={{ fontWeight: '700' }}>
							the previous week starting from day before yesterday.
						</span>
					</Typography>
					<Typography sx={{ marginBottom: 2, paddingLeft: 2 }}>
						<BlackDot /> Thus, if say today is{' '}
						<span style={{ fontStyle: 'italic' }}>30th October</span>, the data
						will be available from{' '}
						<span style={{ fontStyle: 'italic' }}>28th October</span> (today
						minus two days) to one week before 28th October; i.e:{' '}
						<span style={{ fontStyle: 'italic' }}>22nd October</span>. (or today
						minus eight days).
					</Typography>
				</Box>

				<Box sx={{ marginBottom: 3 }}>
					<Typography
						sx={{ fontWeight: 'bold', color: '#0b3452', textAlign: 'left' }}
					>
						Request:
					</Typography>
					<ParamTable headers={reqHeaders} values={reqValues} />
				</Box>

				<Box>
					<Typography
						sx={{ fontWeight: 'bold', color: '#0b3452', textAlign: 'left' }}
					>
						Response:
					</Typography>
					<ParamTable headers={resHeaders} values={resValues} />
				</Box>
			</Box>

			<div style={{ marginTop: 16 }}>
				<FAQSection
					title="Frequenctly Asked Questions (FAQs)"
					faqs={lensFAQs}
				/>
			</div>

			<RapiDocReact
				spec-url={'https://lens.finvu.in/v3/api-docs'}
				show-info={false}
				show-header={false}
				render-style="view"
				primary-color="#0b3452"
				bg-color="#FAFBFC"
				schema-style="table"
				font-size="large"
				text-color="#000000"
				regular-font='"Roboto","Helvetica","Arial",sans-serif'
				mono-font='"Roboto","Helvetica","Arial",sans-serif'
			>
				<span id="auth" slot="servers" /> {/* Hack to scroll to right place */}
				<span id="discovery" slot="tag--Discovery-Summary-API" />
			</RapiDocReact>
		</>
	);
});

export default LensPage;
